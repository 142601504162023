'use client';

import React from 'react';
import Link from 'next/link';
export default function NotFound() {
  return <div className="App-content min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-primary-default sm:text-5xl sm:tracking-tight">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight">
                OOps! Page not found
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link href="/dashboard" className="btn-primary w-auto" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">
                Go back home
              </Link>
              <Link href="/help-center" className="btn-secondary" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">
                Contact support
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>;
}